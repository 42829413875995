body {
  background-color:#222;
  color: #222;
  max-width:100%;
  overflow-x:hidden;
}

a:hover {
  text-decoration: none;
}

.alert {
  margin-bottom:0px !important;
  border-radius:0px !important;
  text-align:center;
}

.alert-success {
  background-color:#0a0;
  color:#fff;
  font-weight:bold;
}

.row.padding {
  padding:25px;
}

.bg-white {
  background-color:#fff;
  color: #000;
}

.bg-darkred {
  background-color:#a00;
  color:#fff;
}

.bg-darkred.border-bottom {
  border-bottom:1px solid #800;
}

.bg-lightgrey {
  background-color:#efefef;
}

.bg-lightgrey.border-bottom {
  border-bottom:1px solid #dedede;
}

.bg-lightgrey.border-top {
  border-top:1px solid #dedede;
}

.bg-lightergrey {
  background-color:#f4f4f4;
}

.bg-grey {
  background-color:#333;
  color:#fff;
}

.bg-darkgrey {
  background-color:#111;
  color:#fff;
}

.btn.attention {
  color:#fff;
  background-color:#151515;
  font-size:1.4em;
}

.btn.attention:hover {
  color:#fff;
  background-color:#000;
}

.service {
  padding:20px;
  cursor:pointer;
}

.service i {
  font-size:3em;
  padding:10px;
}

.service:hover {
  background-color:#eee;
}

.service.current {
  background-color:#eee;
}

.btn {
  border-radius:0;
}

.margin {
  margin:10px;
}

.employee {
  padding-top:20px;
  padding-bottom:20px;
  border-bottom:1px solid #ddd;
}

.employee.no-border {
  border-bottom:none;
}

.employee img {
  max-width:100%;
  max-height:180px;
}

.employee .detail {
  margin-top:40px;
}

.company {
  padding:20px;
}

.company .logo img {
  max-width:200px;
}

.company .testimonial {
  text-align:center;
  padding-left:30px;
  padding-right:30px;
}

.title h3 {
  margin-bottom:20px;
}

.col.align-middle {
  display:inline-block;
  height:100%;
  vertical-align: middle;
}

.technology img {
  max-width:100px;
  max-height:50px;
  margin:20px;
}

.technology img.lg {
  max-height:60px;
  max-width:120px;
}

.contact-details {
  padding:10px;
}

.contact .form-wrapper {
  border:1px solid #ddd;
  padding:20px;
  text-align:left;
}

@media (max-width: 480px) {
  .social {
    zoom: 0.8;
  }
}

.social {
  position:fixed;
  top:10px;
  right:20px;
  padding:10px;
}

.social a {
  cursor:pointer;
  color:#fff;
}

.social a:hover {
  color:#fff;
}

.social img {
  height:25px;
  margin-left:10px;
  margin-right:10px;
}

.social i {
  font-size:1.4em;
  position:relative;
  top:4px;
  margin-left:10px;
  margin-right:10px;
}

.social i.large {
  font-size:1.8em;
  top:6px;
  margin-top:-3px;
}

.form-control:focus {
  box-shadow:none;
  border-color:#a00;
}